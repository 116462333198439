<template>
	<div class="companycustomer">
		<div class="my-title">
			<p>我推荐给悦融易的客户</p>
		</div>
		<div
			style="
				padding: 10px 0 20px 0;
				box-shadow: 0px 3px 3px 1px #e7e7e7;
				position: relative;
				z-index: 10;
			"
		>
			<div style="padding: 0 0 0 20px">
				<span>客户名称:</span>
				<el-input
					style="width: 20%; margin: 0 10px"
					placeholder="请输入客户名称"
					v-model="params.clientName"
				></el-input>
				<el-button
					type="primary"
					@click="
						() => {
							params.pageNo = 1;
							getRecommendClientList(true);
						}
					"
					>查询</el-button
				>

				<el-button
					type="primary"
					@click="
						() => {
							params.pageNo = 1;
							params.clientName = '';
							getRecommendClientList(true);
						}
					"
					>清空</el-button
				>
				<el-button type="primary" @click="dialogVisible = true"
					>推荐客户给悦融易</el-button
				>
			</div>
		</div>
		<div
			style="overflow: auto; height: calc(100vh - 260px); padding: 10px 0"
			v-infinite-scroll="load"
			:infinite-scroll-disabled="disabled"
			v-if="isempty"
			class="test-1"
		>
			<div
				style="padding: 0 10px 10px 10px"
				v-for="(item, index) in list"
				:key="index"
			>
				<el-card class="box-card">
					<p
						@click="showDialogVisible(item)"
						style="
							font-size: 22px;
							font-weight: 800;
							color: #23924f;
							cursor: pointer;
						"
					>
						{{ item.clientName }}
					</p>
					<p style="padding: 20px 0 0 10px">
						{{ item.linkMan }} {{ item.mobilePhone }}
					</p>
					<div style="padding-top: 20px">
						<p style="font-weight: 800">融资意向</p>
						<p
							style="
								padding: 10px 0 0 10px;
								line-height: 20px;
								word-wrap: break-word;
							"
							v-html="fn(item.financingIntention || '')"
						></p>
					</div>
					<div style="padding-top: 20px">
						<p style="font-weight: 800">推荐理由</p>
						<p
							style="
								padding: 10px 0 0 10px;
								line-height: 20px;
								word-wrap: break-word;
							"
							v-html="fn(item.recommendReason || '')"
						></p>
					</div>

					<div style="padding-top: 20px">
						<p style="font-weight: 800">关注问题</p>
						<p
							style="
								padding: 10px 0 0 10px;
								line-height: 20px;
								word-wrap: break-word;
							"
							v-html="fn(item.concernedProblems || '')"
						></p>
					</div>
					<div style="padding-top: 20px">
						<p style="font-weight: 800">备注</p>
						<p
							style="
								padding: 10px 0 0 10px;
								line-height: 20px;
								word-wrap: break-word;
							"
							v-html="fn(item.bankRemark || '')"
						></p>
					</div>
				</el-card>
			</div>
			<div v-if="list.length">
				<p
					style="text-align: center; color: rgba(0, 0, 0, 0.45)"
					v-if="loading"
				>
					加载中
				</p>
				<p
					style="text-align: center; color: rgba(0, 0, 0, 0.45)"
					v-if="noMore"
				>
					已加载全部数据
				</p>
			</div>
		</div>
		<div style="padding-top: 65px" v-else>
			<el-empty description="没有找到符合条件的数据"></el-empty>
		</div>
		<infoDrawer
			@closeVisible="closeVisible"
			:dialogVisible.sync="dialogVisible"
		/>
		<publicInfoDrawer
			:allData="allData"
			:privacyType="privacyType"
			:dialogVisible.sync="publicDialogVisible"
			:paramsId="paramsId"
			@closeVisible="publicDialogVisible = false"
		/>
	</div>
</template>

<script>
import infoDrawer from './module/infoDrawer.vue';
import { recommendClientList } from '@/api/index.js';
import { myMixin } from '@/mixins';
export default {
	name: 'Companycustomer',
	mixins: [myMixin],
	data() {
		return {
			params: { clientName: '', pageSize: 10, pageNo: 1 },
			dialogVisible: false,
			list: [],
			loading: false,
			totalRows: 0,
			isempty: true,
		};
	},
	methods: {
		fn(str = '') {
			return str.replace(/\n/g, '<br />');
		},
		async getRecommendClientList(b = false) {
			const res = await recommendClientList(this.params);
			if (res.returncode == 0) {
				if (b) {
					this.list = [];
				}
				setTimeout(() => {
					this.list = this.list.concat(res.list);
					this.totalRows = res.totalRows;
					this.isempty = this.list.length ? true : false;
				}, 0);
			}
		},
		closeVisible(b) {
			if (b) {
				this.params.pageNo = 1;
			}
			this.dialogVisible = false;
			this.getRecommendClientList(true);
		},
		load() {
			this.params.pageNo = this.params.pageNo + 1;
			this.loading = true;
			this.getRecommendClientList();
			this.loading = false;
		},
	},
	created() {
		this.getRecommendClientList();
	},
	components: { infoDrawer },
	computed: {
		noMore() {
			return this.list.length >= this.totalRows;
		},
		disabled() {
			return this.loading || this.noMore;
		},
	},
};
</script>
<style lang="scss" scoped>
.companycustomer {
	width: 100%;
	height: 100%;
	background: #fff;
	overflow: auto;
	position: relative;
}
.box-card {
	width: 100%;
}
.box {
	height: 'calc(100vh - 420px)';
}
</style>
